<template>
    <div class="material__comments" v-if="comments">
        <h2 class="v-h2 material__comments-count">
            {{commentsCount}}
            {{ functions.num2str(commentsCount, ['комментарий', 'комментария', 'комментариев']) }}
        </h2>
        <comments
            :comments="comments"
            :obj-id="materialId"
            :update-comments-count="updateCommentsCount"
            content-type="material"
        />
    </div>
</template>

<script>
import functions from 'assets/js/functions';
import session from '@/api/session'
export default {
    name: 'MaterialComments',
    components: {
        Comments : () => import('@/components/wall/Comments/Comments'),
    },
    props: {
        comments: {
            type: Object,
            required: false,
            default: []
        },
        materialId: {
            type: Number,
            required: true
        },
        isEditor: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    data () {
        return {
            functions
        }
    },
    computed: {
        commentsCount () {
            return this.comments.count
        }
    },
    methods: {
        async updateCommentsCount(type) {
            const request = await session.get(`/api/v1/comment/count/?contentType=${type}&id=${this.materialId}`);
            this.comments.count = request.data.count
        },
    },
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
.material {
    &__comments {
        margin-top: 60px;
    }
}
</style>
